
import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
  components: {
    CustomerInfo,
    PriceSummary,
    MemberCard,
    Receipt,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      net: 'Cart/net',
      cartTel: 'Cart/tel',
      exchangeTel: 'ExchangeOrder/tel',
      paymentChannels: 'Store/paymentChannels',
      exoNet: 'ExchangeOrder/desktopPayMore'
    })
  },
  methods: {
    ...mapActions({
      previewReceipt: 'Order/previewReceipt',
      previewExchangeReceipt: 'Order/previewExchangeReceipt',
      setPayment: 'Cart/setPayment',
      exoSetPayment: 'ExchangeOrder/setPayment'
    })
  }
})
export default class PaymentChannel extends Vue {
  net!: any

  cartTel!: any

  exchangeTel!: any

  previewReceipt!: any

  previewExchangeReceipt!: any

  preReceipt: any = null

  selectBranch!: any

  selectPos!: any

  showFullPriceFormat!: any

  setPayment!: any

  paymentChannels!: any[]

  exoSetPayment!: any

  public readonly exoNet!: any

  async mounted (): Promise<any> {
    if (this.$route.query?.type === 'exchange-order') {
      this.preReceipt = await this.previewExchangeReceipt({ status: 'no_print', ip: 'no print' })
    } else {
      this.preReceipt = await this.previewReceipt({ status: 'no_print', ip: 'no print' })
    }
  }

  checkout (payment: any): void {
    if (this.isExchangeOrder) {
      this.exoSetPayment(payment)
    } else {
      this.setPayment(payment)
    }
    if (payment.type === 'cash') {
      this.$router.push({ name: 'CashCheckout', query: this.$route.query ?? {} })
    } else {
      this.$router.push({ name: 'CardCheckout', query: this.$route.query ?? {} })
    }
  }

  get sumAmount (): string {
    if (!this.preReceipt) return '-'
    return `${this.preReceipt.items.reduce((sum: number, d: any) => sum + d.amount, 0) }`
  }

  get isExchangeOrder (): boolean {
    return this.$route.query?.type === 'exchange-order'
  }

  get payNet (): number {
    return this.isExchangeOrder ? this.exoNet : this.net ?? 0
  }

  get memberTel (): string {
    return this.isExchangeOrder ? this.exchangeTel : this.cartTel
  }

  goBack (): void {
    if (this.isExchangeOrder) {
    this.$router.push({ name: 'ExchangeCart', params: { orderId: `${this.$route.query?.orderId ?? ''}` } })
    } else {
      this.$router.push({ name: 'DesktopCart' })
    }
  }
}
